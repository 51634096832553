<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <h1 class="con_ttl center">
            <span>회원 가입 안내</span>
          </h1>
          <div class="agreement">
            <div class="agreement_ttl">약관 및 개인정보 수집 동의</div>
            <div class="agreement_box">
              <div class="agreement_txt">
                <span>
                  제 1조 (목적)
                  <br />이 약관은 유니윌 위즈페이(이하 “회사”)가 운영하는
                  위즈니(wizzney) 서비스(이하 “플랫폼”)를 이용함에 있어 회사와
                  서비스를 이용하는 이용자(이하 “회원”)의 권리, 의무 및
                  책임사항, 서비스 이용조건 및 절차 등 기본적인 사항을 규정함을
                  목적으로 합니다.
                  <br />
                  <br />
                  제 2조 (약관의 게시와 효력, 개정) <br />1. 회사는 서비스의
                  가입 과정에 본 약관을 게시합니다. <br />2. 회사는 관련 법령에
                  위반되지 않는 범위내에서 본 약관을 개정할 수 있으며, 약관 변경
                  시 시행일로 최소 7일 이상의 고객 고지기간을 둡니다. 변경된
                  약관은 고지기간 중 공지한 시행일로부터 효력을 발생합니다.
                  <br />3. 이용자가 상기 고지기간 중 변경된 약관에 대한 명시적인
                  거절의 의사표시를 하지 않았을 때에는 본 약관 변경에 동의한
                  것으로 간주됩니다. 개정약관에 동의하지 않을 경우, 이용자는
                  약관 제 16조에 따라 서비스 탈퇴를 할 수 있습니다.
                  <br />
                  <br />
                  제 3조 (약관의 해석과 예외 준칙) <br />본 약관에 정의되지 않은
                  사항은 관계법령이 있는 경우 그 규정을 따르며, 그렇지 않은 경우
                  일반적인 관례에 따릅니다.
                  <br />
                  <br />
                  제 4조 (용어의 정리) <br />1. 서비스 : 개인용 컴퓨터(PC),
                  휴대형 단말기, 전기통신설비 등 포함 각종 유무선 장치와 같이
                  구현되는 단말기와 상관없이 회원이 이용할 수 있는 서비스를
                  의미합니다. <br />2. 회원 : 회사와 서비스 이용계약을 체결하고
                  회사가 제공하는 서비스를 이용하는 모든 사용자를 의미합니다.
                  <br />3. 아이디 : 회원의 식별 및 플랫폼 이용을 위하여 회원이
                  등록한 이메일 또는 휴대폰 번호를 의미합니다. <br />4. 비밀번호
                  : 회원의 개인 정보보호 및 확인을 위해서 회원이 정한 문자의
                  조합을 의미합니다. <br />5. 파티장 : 디지털 서비스의 계정
                  공유를 통해 파티를 생성하고 관리하는 그룹장을 의미합니다.
                  <br />7. 공유계정 : 파티장이 플랫폼을 통해 공유하는 디지털
                  정보 서비스 계정을 의미합니다 <br />8. 리워드 : 파티장에게
                  직접 대가를 지급하지 않고 회사가 정하는 일정한 행위를 마친
                  때에 시스템에 적립되는 리워드로서 소멸 전까지 플랫폼상에서
                  상품의 구매나 출금이체 등에 사용할 수 있는 것을 의미합니다.
                  <br />9. 캐시 : 리워드 또는 충전을 통해 위즈니에서 현금처럼
                  사용할 수 있는 사이버 머니로, 캐시를 플랫폼에 일정
                  환전수수료를 지급하면 현금으로 전환신청 할 수 있습니다.
                  <br />9. 콘텐츠서비스 : 파티장을 통해 등록된 디지털 서비스의
                  계정 공유로 이용할 수 있는 디지털 정보 서비스를 제공하는
                  회사를 의미합니다.
                  <br />
                  <br />
                  제 5조 (이용계약의 체결) <br />1. 이용계약은 회원이 플랫폼
                  서비스에서 제공하는 회원 가입 페이지에서 서비스 이용 약관에
                  동의한 후 이용신청을 하고 신청한 내용에 대해서 회사가
                  승낙함으로써 체결됩니다. <br />2. 회사는 이용약관에 동의한 후
                  이용신청한 사용자에 대해서 원칙적으로 접수 순서에 따라 서비스
                  이용을 승낙함을 원칙으로 합니다. 다만 업무 수행상 또는 기술상
                  지장이 있을 경우 일정시간 가입승인을 유보할 수 있습니다.
                  <br />3. 회사는 다음 각 호에 해당하는 신청에 대해서 승낙하지
                  않거나 사후에 이용계약을 해지할 수 있습니다. <br />
                  - 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우
                  <br />- 허위의 정보를 기재하거나, 회사가 필수적으로 입력을
                  요청한 부분을 기재하지 않은 경우 <br />
                  - 부정한 용도로 서비스를 사용하고자 하는 경우 <br />
                  - 이용자의 귀책 사유로 인하여 승인이 불가능하거나 기타 규정한
                  제반 사항을 위반하며 신청하는 경우 <br />
                  - 회사의 정책에 적합하지 않은 회원으로 판단되는 경우 또는
                  서비스 제공이 곤란한 경우 <br />4. 회사는 이용계약 신청자가
                  성인인증을 거치치 않고 일정기간이 지나면 회원 자격을 자동
                  소멸할 수 있습니다. <br />5. 회원은 회사에 언제든지 회원
                  탈퇴를 요청하여 이용 계약을 해지할 수 있습니다. 다만, 파티에
                  소속이 되어있거나 리워드를 보유하고 있는 경우 해당 서비스를
                  소진 / 완료 후 회원 탈퇴를 요청할 수 있습니다. <br />6. 회원은
                  회원 가입 시 기재한 개인정보의 내용에 변경이 발생한 경우, 즉시
                  변경사항을 정정하여 기재하여야 합니다. 변경의 지체로 인하여
                  발생한 회원의 손해에 대해 회사는 책임을 지지 않습니다.
                  <br />7. 회사는 관련 법률 및 회사의 개인정보 처리 방침에서
                  정한 바에 따라 회원에게 제공을 요청하는 회원정보 및 기타정보
                  항목을 추가, 삭제 등 변경하여 수집 및 이용할 수 있습니다.
                  <br />
                  <br />
                  제 6조 (개인정보보호 의무) <br />1. 회사는 정보통신망법 등
                  관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해
                  노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및
                  회사의 개인정보처리방침이 적용됩니다. 다만, 회사의 공식 사이트
                  이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지
                  않습니다. <br />2. 회사는 서비스를 중단하거나 회원이 개인정보
                  제공 동의를 철회한 경우 신속하게 회원의 개인정보를 파기합니다.
                  단, 전자상거래 등에서의 소비자보호에 관한 법률 등 관련
                  법률에서 정하는 바에 따라 일정 정보는 보관할 수 있습니다.
                  <br />3. 회사는 법률에 특별한 규정이 있는 경우를 제외하고는
                  회원의 별도 동의 없이 회원의 계정정보를 포함한 일체의
                  개인정보를 제3자에게 공개하거나 제공하지 아니합니다. <br />4.
                  회사는 향후 제공하는 서비스에서 회원의 편의를 위해서 회원의
                  계정 정보를 사용할 수 있도록 링크 및 기타 방법을 제공할 수
                  있습니다.
                  <br />
                  <br />
                  제 7조 (회원의 아이디 및 비밀번호) <br />1. 회원은 아이디와
                  비밀번호에 관해서 관리책임이 있습니다. <br />2. 회원은 아이디
                  및 비밀번호를 본인 이외의 제3자가 이용하도록 제공하여서는
                  안됩니다. <br />3. 회사는 회원이 아이디 및 비밀번호를 소홀히
                  관리하여 발생하는 서비스 이용상의 손해 또는 회사의 고의 또는
                  중대한 과실이 없는 제3자의 부정이용 등으로 인한 손해에 대해
                  책임을 지지 않습니다. <br />4. 회원은 아이디 및 비밀번호가
                  도용되거나 제3자가 사용하고 있음을 인지한 경우 이를 즉시
                  회사에 통지하고 회사의 안내에 따라야 합니다.
                  <br />
                  <br />
                  제 8조 (회사의 의무) <br />1. 회사는 법령과 이 약관이
                  금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이
                  정하는 바에 따라 지속적이고, 안정적으로 재화·용역을 제공하는데
                  최선을 다하여야 합니다. <br />2. 회사는 회원이 안전하게
                  서비스를 이용할 수 있도록 서비스의 성격에 적합한 보안시스템을
                  갖추고 운영해야 합니다. <br />3. 회사는 서비스를 이용하는
                  회원으로부터 제기되는 의견이나 불만사항을 1:1 문의 게시판 또는
                  회원 이메일 등을 통해 접수 받은 후 타탕하다고 판단되는 경우
                  플랫폼 서비스에 적극적으로 반영하고 개선하도록 노력합니다.
                  <br />4. 회사는 정보통신망 이용촉진 및 정보보호 등에 관한
                  법률, 전자상거래법 등 서비스의 운영, 유지와 관련 있는 법규를
                  준수합니다.
                  <br />
                  <br />
                  제 9조 (회원의 의무) <br />1. 회원은 다음 각호에 해당하는
                  행위를 해서는 안됩니다. <br />- 이용 신청 또는 회원정보 등록
                  및 변경 시 허위내용 입력 <br />
                  - 허락받지 않은 타인의 정보를 이용하여 회원 가입 <br />
                  - 운영자, 회사를 사칭하거나 관련 정보를 도용 <br />- 회사와
                  기타 제3자의 저작권, 영업비밀, 특허권 등 지적재산권에 대한
                  침해 <br />
                  - 회사와 다른 회원 및 기타 제3자를 희롱하거나, 위협하거나
                  명예를 손상시키는 행위 <br />
                  - 해킹을 통해서 다른 사용자의 정보를 취득하는 행위 <br />
                  - 기타 현행 법령에 위반되는 불법적인 행위 <br />
                  - 다른 회원에게 부정거래(제18조)를 유도하는 행위<br />
                  2. 회사는 회원이 전항에서 금지한 행위를 하는 경우, 위반 행위의
                  경중에 따라 서비스의 이용정지/계약의 해지 등 서비스 이용 제한,
                  수사 기관에의 고발 조치 등 합당한 조치를 취할 수 있습니다.
                  <br />3. 회원은 회사의 명시적 사전 동의가 없는 한 서비스의
                  이용권한 및 기타 이용계약 상의 지위를 본인을 제외한 제3자에게
                  양도, 증여, 대여할 수 없으며 이를 담보로 제공할 수 없습니다.
                  <br />4. 회원은 관계법, 이 약관의 규정, 이용안내 및 서비스와
                  관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야
                  하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
                  <br />5. 회원은 회사의 사전 허락 없이 회사가 정한 이용 목적과
                  방법에 반하여 영업/광고활동 등을 할 수 없고, 회원의 서비스
                  이용이 회사의 재산권, 영업권 또는 비즈니스 모델을 침해하여서는
                  안됩니다.
                  <br />
                  <br />
                  제 10조 (서비스의 제공 및 변경) <br />1. 회사는 회원에게
                  아래와 같은 서비스를 제공합니다. <br />
                  - 위즈니 서비스 <br />
                  - 회사가 제공하는 다른 서비스와의 연계 <br />- 타 회사와
                  제휴계약 등을 통해 회원에게 제공하는 일체의 서비스 외 <br />2.
                  위즈니 서비스는 장비의 유지∙보수를 위한 정기 또는 임시 점검
                  또는 다른 상당한 이유로 서비스의 제공이 일시 중단될 수 있으며,
                  이때에는 미리 서비스 제공화면에 공지합니다. 만약, 회사가
                  예측할 수 없는 이유로 서비스가 중단된 때에는 상황이 파악되는
                  즉시 통지합니다. <br />3. 회사는 회원과 별도로 서면 계약을
                  체결하여 서비스의 브랜드 특성을 이용할 수 있는 명시적인 권리를
                  부여하지 아니하는 한, 회원에게 회사 또는 서비스의 상호, 상표,
                  서비스표, 로고, 도메인 네임 및 기타 식별력 있는 브랜드 특성을
                  이용할 수 있는 권리를 부여하지 않습니다. <br />4. 회사가
                  제공하는 서비스의 형태와 기능, 디자인 등은 필요한 경우 수시로
                  변경되거나 중단될 수 있습니다. 회사는 이 경우 개별적인 변경에
                  대해서 회원에게 사전 통지하지 않습니다. 다만, 회원에게 불리한
                  것으로 판단되는 경우 알림 메시지 또는 공지사항을 통해 사전
                  통보 할 수 있습니다. <br />5. 전항에 의해서 제공되는 서비스가
                  변경 및 중단될 경우 변경 규모 및 유료 서비스 여부에 따라
                  보상이 이루어질 수 있으며, 보통의 경우 별도의 보상은
                  이루어지지 않습니다.
                  <br />
                  <br />
                  제 11조 (서비스 이용 시 주의사항) <br />1. 파티장은 정확한
                  콘텐츠서비스의 아이디와 비밀번호를 제공할 의무가 있으며, 이를
                  지키지 않을 경우 리워드 미지급, 이용계약 해지 등 서비스 이용에
                  차질이 생길 수 있습니다. <br />2. 플랫폼의 거래와 관련된
                  서비스 이용은 만 19세 이상의 성인인증자만 사용할 수 있습니다.
                  <br />3. 위즈니는 법령상 허용되는 한도 내에서 본 약관에
                  명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지
                  않습니다. <br />4. 위즈니는 통신판매중개자이며 통신판매의
                  당사자가 아닙니다. 따라서 개별 공급자가 등록하여 공급한 상품에
                  대한 거래정보 및 거래에 대한 책임은 공급자가 부담하며, 이에
                  대해 위즈니는 어떠한 책임과 의무를 지니지 아니합니다. <br />5.
                  위즈니는 사용자 간 거래 및 캐시의 현금출금에 대한 플랫폼
                  사용대가를 결제금액에 포함하여 수수료로 받을 수 있으며, 판매액
                  또는 출금신청액을 제외한 수수료에 대한 부분만 위즈니에
                  귀속됩니다. 수수료에 대한 현금영수증은 회원의 휴대폰 번호로
                  자동 발급 됩니다. <br />6. 위즈니 서비스에 속한 콘텐츠,
                  서비스의 특정 기능, 서비스의 이용가능성에 대하여 어떠한
                  약정이나 보증을 하는 것이 아니며, 파티장이 공유한 디지털
                  서비스를 있는 그대로 제공할 뿐입니다. <br />7. 회원은 자신이
                  사용하는 콘텐츠 서비스의 이용약관 및 이용 가능 범위를 확인하여
                  그 약관상 의무를 준수하는 범위에서 회사의 서비스를 이용해야
                  합니다.
                  <br />
                  <br />
                  제 12조 (리워드 출금정책) <br />1. 받은 리워드는 캐시의 형태로
                  적립되며. 플랫폼 내에서 현금처럼 쓰거나, 10,000캐시 이상인
                  경우 플랫폼에 일정 수수료를 지급하고 현금으로 환전이
                  가능합니다. <br />2. 출금신청은 영업일 기준 매주 월, 수, 금
                  처리되며 해당 요일이 휴무일인 경우, 신청건은 다음 출금일에
                  처리됩니다. <br />3. 출금 신청마감은 출금일 기준 전일 24:00
                  시를 기점으로 마감이 원칙이며, 자정 이후 신청건은 다음
                  출금일에 출금 처리 됩니다. <br />3. 전환 비율은 1 캐시당 ￦1을
                  원칙으로 합니다. <br />4. 통장에서 출금시 발생하는 금융기관
                  수수료는 회원의 부담입니다.
                  <br />
                  <br />
                  제 13조 (광고의 게제) <br />1. 회사는 서비스 운영과 관련하여
                  회원정보, 회원이 입력한 정보를 활용하여 광고를 게재할 수
                  있습니다. 회원은 서비스 이용 시 노출되는 맞춤 광고 게재에 대해
                  동의합니다. <br />2. 회사는 서비스상에 게재되어 있거나
                  서비스를 통한 광고주의 판촉활동에 회원이 참여하거나 교신 또는
                  거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지
                  않습니다.
                  <br />
                  <br />
                  제 14조 (서비스 이용의 제한) <br />1. 회사는 천재지변이나
                  국가비상사태, 해결이 곤란한 기술적 결함 또는 서비스 운영의
                  심각한 변화 등 불가항력적인 경우가 발생 또는 발생이 예상될
                  때는 서비스의 전부 또는 일부를 예고 없이 제한하거나 중지할 수
                  있습니다. <br />2. 서비스를 이용하게 됨으로써 이용약관에
                  명시된 사항 및 서비스 영역 외에서 발생하는 회원 사이의 문제에
                  대해 회사는 책임을 지지 않습니다. <br />3. 회원의 관리 소홀로
                  인하여 아이디 및 비밀번호의 유출로 인해 회원에게 서비스
                  이용상의 손해가 발생하거나 제3자에 의한 부정이용 등으로 회원의
                  의무조항을 위반한 경우 아이디의 이용이 제한될 수 있습니다.
                  <br />4. 회사가 본 약관 제9조의 위반 행위를 조사하는 과정에서
                  당해 회원 아이디의 특정 위반행위에 직접적으로 관련되어 있는
                  경우 등 다른 회원의 권익 보호 및 서비스의 질서유지를 위해
                  불가피할 경우에는 해당 아이디의 이용을 일시적으로 정지할 수
                  있습니다. 이에 대해 회원은 서비스 홈페이지를 통해 이의신청을
                  할 수 있습니다.
                  <br />
                  <br />
                  제15조 (서비스 이용의 중지 및 해지) <br />1. 회원은 유료상품의
                  거래중인 상태가 아니며, 리워드 및 충전 캐시를 보유하지 않는
                  경우에 한하여 회사에 회원 탈퇴를 요청할 수 있으며, 회사는 이와
                  같은 요청을 받았을 경우, 회사가 별도로 고지한 방법에 따라
                  신속하게 처리합니다. <br />2. 회원이 서비스의 이용중지를
                  원하는 경우에는 회사가 제공하는 서비스 페이지로 회사에
                  중지신청을 할 수 있습니다. 회사는 이와 같은 요청을 받았을
                  경우, 회사가 별도로 고지한 방법에 따라 신속하게 처리합니다.
                  <br />3. 회사는 회원이 본 약관 제9조의 회원의 의무를 위반한
                  경우 및 서비스의 정상적인 운영을 방해한 경우에는 사전 통보 후
                  회원 자격을 제한, 이용계약을 해지하거나 또는 기간을 정하여
                  서비스의 이용을 중지할 수 있습니다. <br />4. 회사는 전항에도
                  불구하고, 저작권법을 위반한 불법 프로그램의 제공 및 운영방해,
                  정보통신망법을 위반한 불법통신 및 해킹, 악성 프로그램의 배포,
                  접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시
                  영구이용정지를 할 수 있습니다. <br />5. 회사는 회원이 계속해서
                  3개월 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의
                  효율성을 위해 본인 식별 과정등을 통해 회원의 서비스 이용자격을
                  갱신할 수 있습니다. <br />6. 회원은 본 조에 따른 이용제한 등에
                  대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때
                  이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의
                  이용을 재개합니다.
                  <br />
                  <br />
                  제16조 (책임제한) <br />1. 회사는 회원의 약관, 서비스 이용
                  방법 및 이용 기준을 준수하지 않는 등 회원의 귀책사유로 인한
                  서비스 이용의 장애에 대하여는 책임을 지지 않습니다. <br />2.
                  회사는 서비스를 통하여 게재한 정보, 자료, 사실의 신뢰도,
                  정확성 등의 내용에 관하여는 보증하지 않습니다. <br />3. 회사는
                  회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래
                  등을 한 경우에 대하여 책임이 면제됩니다. <br />4. 회사는
                  무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이
                  없는 한 책임을 지지 않습니다. <br />5. 회사는 천재지변, 전쟁,
                  기간통신회사의 서비스 중지, 제3자가 제공하는 오픈아이디의 인증
                  장애, 해결이 곤란한 기술적 결함 기타 불가항력으로 인하여
                  서비스를 제공할 수 없는 경우 책임이 면제됩니다. <br />6.
                  회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검,
                  공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한
                  경우에 대하여 책임이 면제됩니다. <br />7. 회원은 자신의 결정에
                  의하여 회사의 서비스를 사용하여 특정 프로그램이나 정보 등을
                  다운로드받거나 접근함으로써 입게 되는 컴퓨터 시스템상의 손해나
                  데이터, 정보의 상실에 대한 책임을 집니다. <br />8. 회사는
                  기간통신회사가 전기통신서비스를 중지하거나 정상적으로 제공하지
                  아니하여 손해가 발생한 경우에 대하여 책임이 면제됩니다.
                  <br />9. 회원의 컴퓨터 오류, 신상정보의 부정확한 기재,
                  비밀번호 관리의 소홀 등 회원의 귀책사유로 인해 손해가 발생한
                  경우에 대하여 회사는 책임을 지지 않습니다. <br />10. 회사는
                  회원의 컴퓨터 환경 문제나 회사의 관리 범위에 있지 아니한 보안
                  문제로 인하여 발생하는 제반 문제 또는 현재의 보안기술 수준으로
                  방어가 곤란한 네트워크 해킹 등 회사의 귀책사유 없이 발생하는
                  문제에 대해서 책임을 지지 않습니다. <br />11. 회사는 서비스 내
                  제공한 내용에 대한 중요 정보의 정확성, 내용, 완전성, 적법성,
                  신뢰성 등에 대하여 보증하거나 책임을 지지 않으며, 사이트의
                  삭제, 저장실패, 잘못된 인도, 정보 제공에 대한 궁극적인 책임을
                  지지 않습니다. <br />12. 회사는 회원 상호간 또는 회원과 제3자
                  상호 간에 서비스를 매개로 발생한 이용약관에 명시되어 있지 않은
                  분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할
                  책임도 없습니다. <br />13. 회사는 회원이 서비스를 이용하여
                  기대하는 효용을 얻지 못한 것에 대하여 책임을 지지 않으며
                  서비스에 대한 취사 선택 또는 이용으로 발생하는 손해 등에
                  대하여 책임이 면제됩니다.
                  <br />
                  <br />
                  제17조 (준거법 및 재판관할) <br />1. 회사와 회원 간 제기된
                  소송에는 대한민국법을 준거법으로 합니다. <br />2. 회사와
                  회원간 발생한 분쟁에 관한 소송은 민사소송법 상의 관할법원에
                  제소합니다. <br /><br />
                  제18조(부정 거래)<br />
                  1. 직거래 유도 및 직거래 금지<br />
                  - 위즈니는 판매자와 구매자가 물품 또는 계정을 직접 주고받는
                  직거래 행위를 금지합니다.<br />
                  - 위즈니는 개인 채널 홍보 또는 개인 채널을 이용한 직거래
                  유도를 금지합니다.<br />
                  2. 직거래 유형<br />
                  - 판매자가 여러 가지 사유로 위즈니 서비스에서 취소/환불을
                  권유하고, 구매자에게 개인 계정을 알려줌으로써 거래를 유도하는
                  경우<br />
                  - 위즈톡, 이메일, 유선 등을 통해 구매자에게 직거래 의사를 묻는
                  행위<br />
                  3. 직거래 피해<br />
                  - 판매자가 거래 중간에 연락을 끊고 잠적함<br />
                  - 판매자가 카카오톡 오픈 채팅방을 이용하여, 자신의 별도 계좌로
                  송금 받고 계정 및 물품을 보내주지 않음<br />
                  - 구매자가 계정 및 물품을 받았음에도 불구하고, 못 받았다고
                  주장함<br />
                  - 구매가 계좌 이체를 하지 않았음에도 불구하고, 계좌
                  이체하였다고 주장함<br />
                  4. 직거래 관련 신고/적발 시 제재 사항<br />
                  - 해당 상품 및 계정 판매금지 조치<br />
                  - 캐시 출금 및 결제 금액 환불 불가<br />
                  - 위즈니 관리자의 직권에 의해서 회원 자격 영구정지 조치
                  <br />
                  <br />부 칙 시행일 등 <br />이 약관은 2021년 04월 20일부터
                  시행되며 이전의 약관은 본 약관으로 변경됩니다. <br
                /></span>
              </div>
              <label class="checkbox">
                <input
                  type="checkbox"
                  :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
                  :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
                  v-model="useAgree"
                  :checked="useAgree === $ConstCode.BOOLEAN_VALUE.TRUE"
                />
                <span class="label"
                  ><span>서비스 이용약관에 동의합니다.</span></span
                >
              </label>
            </div>
            <div class="agreement_box">
              <div class="agreement_txt">
                <span
                  >주식회사 유니윌 위즈페이(이하 '회사')는 고객의 개인정보를
                  매우 중요하게 생각하며, '정보통신망 이용촉진 및 정보보호 등에
                  관한 법률', '개인정보 보호법'에 따라 정보통신서비스 제공자가
                  준수하여야 할 관련 법령상의 규정을 준수하며, 이용자의 소중한
                  개인정보에 대해 권익을 보호하고 개인정보와 관련한 이용자의
                  고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고
                  있습니다. <br />회사는 개인정보 처리방침을 웹 사이트 첫 화면에
                  공개함으로서 이용자들이 언제나 용이하게 보실 수 있도록
                  조치하고 있습니다. <br />본 개인정보처리방침은 개인정보보호와
                  관련한 법률 또는 지침의 변경, 당사 정책의 변화에 따라 변경될
                  수 있으므로, 이용자께서는 당사의 웹 사이트 방문 시 수시로
                  확인하여 주시기 바랍니다. <br />1. 개인정보의 처리 목적
                  <br />회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리한
                  개인정보는 다음의 목적 이외의 용도로는 사용되지 않습니다.
                  <br />
                  <br />① 사이트 회원가입 및 관리 <br />서비스 제공에 따른 본인
                  인증 및 이용자 식별, 회원자격 유지 및 관리, 불량회원 부정이용
                  방지, 각종 고지 및 통지, 민원 처리, 분쟁 조정을 위한 기록
                  보존, 회원탈퇴 의사의 확인 등을 목적으로 개인정보를
                  처리합니다. <br />② 각 서비스 제공 <br />서비스 제공, 컨텐츠
                  제공, 거래 중개서비스 제공, 본인인증, 실명인증, 계좌인증,
                  마일리지 입출금 등을 목적으로 개인정보를 처리합니다. <br />③
                  마케팅 및 광고에의 활용 <br />신규 서비스(컨텐츠) 개발 및
                  제공, 이벤트 및 광고성 정보 제공과 참여기회 제공, 서비스 이용
                  기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및
                  거래 신뢰도, 성사도 통계에 따른 맞춤 서비스 제공, 광고 게재
                  등을 목적으로 개인정보를 처리합니다. <br />④ 제 3자 제공 관련
                  <br />위즈니에서 수집한 회원의 개인정보는 위즈니
                  “개인정보보호” 내 고지한 범위내에서 사용하며, 고객의 동의가
                  있거나 법률의 규정에 의한 경우를 제외하고는 동 범위를 넘어서
                  회원의 개인정보를 제 2자 또는 외부에 제공 또는 공유하지
                  않습니다. 그러나, 보다 나은 서비스 제공을 위하여 개인정보를
                  마케팅에 활용하거나 제휴사에 제공 및 공유할 필요가 있는 경우,
                  마케팅 활용 동의를 명시하여 동의를 구하는 절차를 거치며,
                  동의하지 않은 경우 마케팅에 활용하거나 제 3자에게 제공하지
                  않습니다. <br />가. 개인정보 제 3자 제공 예외 사항 <br />-
                  배송업무 상 배송업체에게 배송에 필요한 정보를 알려주는 경우
                  <br />- 통계작성, 학술연구, 또는 시장조사 등을 위하여
                  특정개인을 식별할 수 없는 형태로 제공하는 경우 <br />-
                  관련법에 의거 적법한 절차에 의한 정부기관의 요청이 있는 경우
                  <br />나. 마케팅활용 동의 철회 <br />마케팅 활용 동의
                  철회권이란 회사에서 직접 진행하거나, 제휴사를 통한 마케팅
                  진행에 대한 동의철회권을 뜻하며, 위즈니의 개인정보 수정
                  페이지에서 동의 처리한 SMS 마케팅 및 Email 마케팅 동의를
                  해지하거나, 수신 이메일의 수신 거부를 통해 마케팅 활용 동의를
                  철회 할 수 있습니다. <br />④ 보안에 따른 활용 <br />보안,
                  프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는
                  서비스 이용환경 구축을 위해 개인정보를 이용합니다.
                  <br />
                  <br />2. 개인정보의 처리 및 보유기간 <br />회사는 다음의
                  개인정보 항목을 처리하고 있습니다.
                  <br />
                  <br />개인정보 항목 <br />
                  - 필수 수집 항목 : 이메일, 휴대전화번호, 비밀번호, 성별,
                  생년월일, 이름(예금주), 은행계좌정보 <br />
                  - 서비스 이용 과정이나 처리 과정에서 자동으로 생성되는 정보 :
                  서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제 기록
                  <br />
                  - 본인 확인 시 추가 수집 정보 : 신분증 사본 (생년월일을 제외한
                  나머지 정보는 공백으로 하여 제공받음), 통장 사본, 송금
                  확인증、e쿠폰/마일리지 발행회사 계정의 고객정보 사본 <br />
                  - 고객 간 거래 시 추가 수집 정보 : e쿠폰/마일리지 이체 내역
                  또는 영수증 사본 <br />그 외, 고객지원을 통한 상담 과정에서 웹
                  사이트, 이메일, 팩스, 전화 등을 통해 이용자의 개인정보가
                  수집될 수 있습니다. <br />수집방법 : 최초 회원 가입 시 /
                  본인확인 업무 시 회원이 직접 제공 <br />보유근거 : 회사는
                  이용자들이 회사의 개인정보 처리방침 또는 이용약관의 내용에
                  대하여 [동의] 또는 [취소] 란에 체크 또는 버튼을 클릭할 수 있는
                  절차를 마련하여, 회원이 [동의]에 체크하거나 버튼을 클릭하면
                  개인정보 수집에 대해 동의한 것으로 봅니다. <br />보유기간 :
                  이용자의 탈퇴 요청 및 개인정보 동의를 철회하는 때까지. 다만,
                  회사는 회사의 약관에 따른 회원의 부정이용기록 또는 불량회원의
                  이용정보 기록, 부정이용이 의심되는 기록이 있는 경우에는
                  이용자의 탈퇴 요청 및 개인정보 동의의 철회에도 불구하고, 수집
                  시점으로부터 5년간 보관하고 파기합니다. <br />회사의
                  개인정보처리방침에도 불구하고, 아래와 같은 관련법령에 의하여
                  보관하여야 하는 정보는 법령이 정한 기간 동안 보관됩니다.
                  <br />
                  <br />① 서비스 이용 관련 개인정보(로그인기록) <br />
                  보존 근거 : 통신비밀보호법 <br />
                  보존 기간 : 3개월 <br />② 표시/광고에 관한 기록 <br />
                  보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률 <br />
                  보존 기간 : 6개월 <br />③ 계약 또는 청약철회 등에 관한 기록
                  <br />
                  보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률 <br />
                  보존 기간 : 5년 <br />④ 대금결제 및 재화 등의 공급에 관한 기록
                  <br />
                  보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률 <br />
                  보존 기간 : 5년 <br />⑤ 소비자의 불만 또는 분쟁처리에 관한
                  기록 <br />
                  보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률 <br />
                  보존 기간 : 3년 <br />⑥ 전자금융 거래에 관한 기록 <br />
                  보존 근거 : 전자금융거래법 <br />
                  보존 기간 : 5년 <br />⑦ 부정거래 기록 <br />
                  보존 기간 : 1년 <br />
                  보존 항목 : 이메일, 휴대폰번호, 생년월일, 이름, 부정거래 사유,
                  탈퇴 시 회원 상태값 등 <br />
                  부정거래 : 법령, 회사와 이용자 간의 서비스 이용 약관 또는
                  공서양속을 위반하거나 기타 회사, 회원 또는 타인의 권리나
                  이익을 침해하는 방법이나 내용의 거래를 말함. <br />3.
                  개인정보처리 위탁 <br />회사는 서비스 향상 및 원활한 개인정보
                  업무처리를 위하여 업무 중 일부를 외부에 위탁하고 있으며.
                  회사는 원칙적으로 이용자 동의 없이 개인정보를 외부에 제공하지
                  않습니다. 단, 이용자가 외부 제휴사의 서비스를 이용하기 위하여
                  개인정보 제공에 직접 동의를 한 경우, 그리고 관련 법령에 의거해
                  회사가 개인정보 제출 의무가 발생한 경우에 개인정보를 제공하고
                  있습니다. 회원은 개인정보 위탁(제공)에 대한 동의를 거부할
                  권리가 있으나, 동의를 거부할 경우 서비스 이용에 제한을 받을 수
                  있습니다.
                  <br />
                  <br />① 수탁업체 : 나이스신용평가정보(주) <br />
                  위탁업무 내용 :휴대폰의 본인 소유 여부 확인 및 실명 인증을
                  위한 SMS 인증서비스 <br />② 수탁업체: ㈜세틀뱅크 <br />
                  위탁업무 내용 : 예금주 조회서비스, 출금서비스, 출금계좌인증 ,
                  무통장입금(가상계좌) 결제, 출금서비스, 출금계좌인증
                  <br />
                  <br />위탁업체의 개인정보 보유 기간은 회원탈퇴 시 혹은
                  위탁계약 종료 시, 법령이 정한 시점이며, 각 업체에서 이미
                  보유하고 있는 개인정보의 경우 별도로 저장하지 않습니다.
                  <br />회사는 회사가 개인정보를 위탁업체에 제공하는 경우
                  위탁받은 업체가 정보통신망법 및 개인정보보호 관계 법령을
                  위반하지 않고 개인정보를 안전하게 취급하도록 관리 및 감독하고
                  있습니다. <br />위탁업무의 내용이나 수탁자가 변경될 경우에는
                  지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
                  <br />4. 정보주체의 권리, 의무 및 그 행사방법 <br />이용자는
                  개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.
                  <br />정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보
                  보호 관련 권리를 행사할 수 있습니다.
                  <br />
                  <br />① 개인정보 열람요구 <br />② 오류 등이 있을 경우 정정요구
                  <br />③ 삭제요구 <br />④ 처리정지 요구 <br />제1항에 따른 권리
                  행사는 회사에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에
                  따라 서면, 전자우편 등을 통하여 하실 수 있으며 회사는 이에
                  대해 지체 없이 조치하겠습니다. <br />정보주체가 개인정보의
                  오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정
                  또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지
                  않습니다. <br />제1항에 따른 권리 행사는 정보주체의
                  법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수
                  있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에
                  따른 위임장을 제출하셔야 합니다. <br />5. 개인정보의 파기
                  <br />회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는
                  지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및
                  방법은 다음과 같습니다.
                  <br />
                  <br />① 파기절차 : 이용자가 입력한 정보는 목적 달성 후 별도의
                  DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련
                  법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때,
                  DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른
                  목적으로 이용되지 않습니다. <br />② 파기기한 : 이용자의
                  개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의
                  종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당
                  서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을
                  때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일
                  이내에 그 개인정보를 파기합니다. <br />③ 파기방법 : 전자적
                  파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
                  사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나
                  소각을 통하여 파기합니다. <br />6. 개인정보의 안전성 확보 조치
                  <br />회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성
                  확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
                  <br />
                  <br />① 해킹 등에 대비한 기술적 대책 <br />회사는 해킹이나
                  컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여
                  보안프로그램을 설치하고 주기적인 갱신•점검을 하며 외부로부터
                  접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시
                  및 차단하고 있습니다. <br />② 개인정보의 암호화 <br />이용자의
                  개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어,
                  본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를
                  암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을
                  사용하고 있습니다. <br />개인정보에 대한 접근 제한
                  <br />개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
                  부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여
                  필요한 조치를 하고 있으며 침입차단시스템을 이용하여
                  외부로부터의 무단 접근을 통제하고 있습니다. <br />③ 문서보안을
                  위한 잠금장치 사용 <br />개인정보가 포함된 서류, 보조저장매체
                  등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다. <br />④
                  비인가자에 대한 출입 통제 <br />개인정보를 보관하고 있는
                  물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립,
                  운영하고 있습니다. <br />회사는 개인정보의 훼손에 대비해서
                  자료를 수시로 백업하고 있고, 시스템적으로 보안성을 확보하기
                  위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
                  <br />7. 개인정보 보호책임자 <br />회사는 개인정보 처리에 관한
                  업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의
                  불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보
                  보호책임자를 지정하고 있습니다.
                  <br />
                  <br />[개인정보 보호책임자] <br />성 명 : 김경현 <br />소 속 :
                  위즈니팀 <br />이메일 : wizzney@wizzpay.com
                  <br />
                  <br />① 정보주체는 회사의 서비스(또는 사업)를 이용하면서
                  발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에
                  관한 사항을 개인정보 보호책임자에게 문의하실 수 있습니다.
                  회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴
                  것입니다. <br />② 이용자 개인정보와 관련한 아이디(ID) 및
                  비밀번호에 대한 보안유지책임은 해당 이용자 자신에게 있습니다.
                  회사는 비밀번호에 대해 어떠한 방법으로도 이용자에게 직접적으로
                  질문하는 경우는 없으므로 타인에게 비밀번호가 유출되지 않도록
                  각별히 주의하시기 바랍니다. 특히 공공장소에서 온라인상에서
                  접속해 있을 경우에는 더욱 유의하셔야 합니다. 회사는 이용자의
                  개인적인 부주의로 아이디, 비밀번호, 핸드폰번호 등 개인정보가
                  유출되어 발생한 문제와 기본적인 인터넷의 위험성 때문에
                  일어나는 일들에 대해 책임을 지지 않습니다. <br />③ 회사가
                  가능한 기술적인 보안조치를 했음에도 불구하고, 첨단 기술을
                  이용한 해킹 등 네트워크 상의 위험에 의해 발생하는 예기치 못한
                  사고로 인한 정보의 훼손에 관해서는 책임이 없습니다. <br />④
                  회사는 본 개인정보 처리방침에도 불구하고 대한민국 법령에
                  근거하여 개인정보 제공 의무를 부담하는 경우, 법원의 명령에
                  따라 이를 제공할 수 있습니다. <br />8. 이용자의 권리와 의무
                  <br />① 이용자의 개인정보를 최신의 상태로 정확하게 입력하여
                  불의의 사고를 예방해주시기 바랍니다. 이용자가 입력한 부정확한
                  정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인
                  정보의 도용 등 허위정보를 입력할 경우 계정의 이용이 제한될 수
                  있습니다. <br />② 이용자는 개인정보를 보호받을 권리를 보유하나
                  이와 동시에 본인의 정보를 스스로 보호하고 또한 타인의 정보를
                  침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한
                  이용자의 개인정보가 유출되지 않도록 조심하시고 타인의
                  개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이와 같은
                  책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는
                  『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 등에 의해
                  처벌받을 수 있습니다. <br />9. 권익침해 구제방법
                  <br />정보주체는 아래의 기관에 대해 개인정보 침해에 대한
                  피해구제, 상담 등을 문의하실 수 있습니다.&lt;아래의 기관은
                  회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리,
                  피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이
                  필요하시면 문의하여 주시기 바랍니다.>
                  <br />
                  <br />① 개인정보 침해신고센터 (한국인터넷진흥원 운영) <br />
                  • 소관업무: 개인정보 침해사실 신고, 상담 신청 <br />
                  • 웹사이트: privacy.kisa.or.kr <br />
                  • 전화: (국번없이) 118 <br />
                  • 주소: (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원
                  개인정보침해신고센터 <br />② 개인정보 분쟁조정위원회
                  (한국인터넷진흥원 운영) <br />
                  • 소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
                  <br />
                  • 웹사이트: privacy.kisa.or.kr <br />
                  • 전화: (국번없이) 118 <br />
                  • 주소: (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원
                  개인정보침해신고센터 <br />③ 대검찰청 사이버범죄수사단 <br />
                  • 웹사이트: www.spo.go.kr <br />
                  • 전화: 02-3480-3573 <br />④ 경찰청 사이버범죄수사단 <br />
                  • 웹사이트: www.netan.go.kr <br />
                  • 전화: 1566-0112 <br />10. 개인정보 처리방침 변경 <br />이
                  개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른
                  변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행
                  7일 전부터 웹사이트 공지사항 또는 개별공지를 통하여 고지할
                  것입니다. <br />11. 회사는 쿠키를 설치, 운영하고 있고 이용자는
                  이를 거부할 수 있습니다. <br />쿠키는 이용자에게 보다 빠르고
                  편리한 웹사이트 사용을 지원하고 다양한 서비스를 제공하기 위해
                  사용됩니다. 다만, 이용자는 쿠키 설치에 대한 선택권을 가지고
                  있습니다. 따라서, 이용자는 웹 브라우저에서 옵션을 설정함으로써
                  모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나,
                  모든 쿠키의 저장을 거부할 수도 있습니다. 또한 쿠키 설치를
                  거부할 경우 웹 사용이 불편해지며 로그인이 필요한 일부 서비스
                  이용에 어려움이 있을 수 있습니다. <br />부칙 <br />(시행일) 이
                  개인정보 처리방침은 2021년 04월 23일부터 적용됩니다.
                  <br />
                  <br
                /></span>
              </div>
              <label class="checkbox">
                <input
                  type="checkbox"
                  :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
                  :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
                  v-model="priAgree"
                  :checked="priAgree === $ConstCode.BOOLEAN_VALUE.TRUE"
                />
                <span class="label"
                  ><span>개인 정보 수집 및 이용에 동의합니다.</span></span
                >
              </label>
            </div>
          </div>
          <div class="agreement">
            <div class="agreement_ttl">
              마케팅 활용 동의 및 광고 수신 동의
            </div>
            <div class="agreement_box">
              <label class="checkbox">
                <input
                  type="checkbox"
                  :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
                  :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
                  v-model="smsAgree"
                  :checked="smsAgree === $ConstCode.BOOLEAN_VALUE.TRUE"
                />
                <span class="label"><span>SMS 수신 동의 (선택)</span></span>
              </label>
            </div>
            <div class="agreement_box" style="padding-top: 0">
              <label class="checkbox">
                <input
                  type="checkbox"
                  :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
                  :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
                  v-model="emailAgree"
                  :checked="emailAgree === $ConstCode.BOOLEAN_VALUE.TRUE"
                />
                <span class="label"><span>E-mail 수신 동의 (선택)</span></span>
              </label>
            </div>
            <label class="checkbox st02">
              <input
                type="checkbox"
                :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
                :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
                v-model="isAll"
                :checked="isAll === $ConstCode.BOOLEAN_VALUE.TRUE"
              />
              <span class="label"
                ><span>모든 약관을 확인하고 전체 동의합니다.</span></span
              >
            </label>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn" @click="fnNext">다음</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'regist-terms-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
