import { reactive, toRefs, computed, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const { dispatch, getters } = useStore()
  const state = reactive({
    registerForm: computed(() => getters['user/getRegisterForm']),
    useAgree: proxy.$ConstCode.BOOLEAN_VALUE.FALSE,
    priAgree: proxy.$ConstCode.BOOLEAN_VALUE.FALSE,
    smsAgree: proxy.$ConstCode.BOOLEAN_VALUE.FALSE,
    emailAgree: proxy.$ConstCode.BOOLEAN_VALUE.FALSE,
    isRequireAll: computed({
      get: () => {
        return state.useAgree === proxy.$ConstCode.BOOLEAN_VALUE.TRUE &&
          state.priAgree === proxy.$ConstCode.BOOLEAN_VALUE.TRUE
          ? proxy.$ConstCode.BOOLEAN_VALUE.TRUE
          : proxy.$ConstCode.BOOLEAN_VALUE.FALSE
      },
      set: val => {
        if (val === proxy.$ConstCode.BOOLEAN_VALUE.TRUE) {
          state.useAgree = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
          state.priAgree = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
        } else {
          state.useAgree = proxy.$ConstCode.BOOLEAN_VALUE.FALSE
          state.priAgree = proxy.$ConstCode.BOOLEAN_VALUE.FALSE
        }
      }
    }),
    isAll: computed({
      get: () => {
        return state.useAgree === proxy.$ConstCode.BOOLEAN_VALUE.TRUE &&
          state.priAgree === proxy.$ConstCode.BOOLEAN_VALUE.TRUE &&
          state.smsAgree === proxy.$ConstCode.BOOLEAN_VALUE.TRUE &&
          state.emailAgree === proxy.$ConstCode.BOOLEAN_VALUE.TRUE
          ? proxy.$ConstCode.BOOLEAN_VALUE.TRUE
          : proxy.$ConstCode.BOOLEAN_VALUE.FALSE
      },
      set: val => {
        if (val === proxy.$ConstCode.BOOLEAN_VALUE.TRUE) {
          state.useAgree = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
          state.priAgree = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
          state.smsAgree = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
          state.emailAgree = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
        } else {
          state.useAgree = proxy.$ConstCode.BOOLEAN_VALUE.FALSE
          state.priAgree = proxy.$ConstCode.BOOLEAN_VALUE.FALSE
          state.smsAgree = proxy.$ConstCode.BOOLEAN_VALUE.FALSE
          state.emailAgree = proxy.$ConstCode.BOOLEAN_VALUE.FALSE
        }
      }
    })
  })
  const fnDone = async registerForm => {
    try {
      const request = {
        loginType: registerForm.loginType,
        id: registerForm.id,
        pw: registerForm.pw,
        niceId: registerForm.niceId,
        niceSeq: registerForm.niceSeq,
        nick: registerForm.nick,
        accountNum: registerForm.accountNum,
        bankCode: registerForm.bankCode,
        useAgree: registerForm.useAgree,
        priAgree: registerForm.priAgree,
        smsAgree: registerForm.smsAgree,
        emailAgree: registerForm.emailAgree
      }
      const params = {
        request: JSON.stringify(request),
        file: ''
      }
      const res = await proxy.$UserSvc.postSnsSignUp(params)
      if (res.resultCode === '0000') {
        await router.replace({ path: '/regist/done' })
      } else {
        alert(res.resultMsg)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const fnNext = async () => {
    if (state.isRequireAll === proxy.$ConstCode.BOOLEAN_VALUE.FALSE) {
      alert('약관 및 개인정보 수집 및 이용에 동의해주세요.')
      return
    }
    const registerForm = Object.assign(getters['user/getRegisterForm'])
    registerForm.useAgree = state.useAgree
    registerForm.priAgree = state.priAgree
    registerForm.smsAgree = state.smsAgree
    registerForm.emailAgree = state.emailAgree
    await dispatch('user/updateRegisterForm', { registerForm })
    if (
      !proxy.$Util.isEmpty(registerForm.loginType) &&
      Number(registerForm.loginType) ===
        proxy.$ConstCode.SNS_LOGIN_TYPE.NAVER.value
    ) {
      await fnDone(registerForm)
    } else {
      // await router.push({ path: '/regist/form' })
      await router.push({ path: '/regist/recommend' })
    }
  }
  return { ...toRefs(state), fnDone, fnNext }
}
